<template>
  <div class="container" v-if="viewedEvents.length">
    <div class="w-full">
      <div
        class="px-4 xl:px-0 text-xl lg:text-4xl font-bold mb-4 lg:mb-5 font-sf-pro text-gray-900"
      >
        Вы смотрели
      </div>
      <div
        class="px-4 xl:px-0 hidden lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-7 transition-all pb-4"
      >
        <div v-for="item in viewedEvents" :key="item.id">
          <t-card :data="item" class="h-full" :has-description="false">
            <template #footer>
              <div class="flex flex-row space-x-3">
                <div
                  class="h-full border border-2 !rounded-lg"
                  :style="{ 'border-color': item.activity.category?.color }"
                />
                <div class="font-semibold text-gray-300 text-lg sf-pro-text">
                  {{ dayjs(item.scheduleInfo.sessionDate).locale('ru').format('D MMMM') }}
                </div>
              </div>
              <div class="font-semibold text-primary-100 text-lg sf-pro-text" v-if="item.minPrice">
                от {{ item.minPrice }} ₽
              </div>
              <div
                class="font-semibold text-primary-100 text-lg sf-pro-text"
                v-else-if="item.tariff.type === 'free'"
              >
                Бесплатно
              </div>
              <div
                class="font-semibold text-primary-100 text-lg sf-pro-text"
                v-else-if="item.tariff.type === 'offline'"
              >
                Билеты в кассе
              </div>
            </template>
          </t-card>
        </div>
      </div>
      <div class="lg:hidden px-4 gap-7 transition-all pb-4 watchSwiper overflow-hidden">
        <div class="swiper-wrapper">
          <div
            v-for="item in viewedEvents"
            :key="item.id"
            class="swiper-slide h-auto"
            :class="viewedEvents.length === 1 ? '!w-full' : ''"
          >
            <t-card :data="item" class="h-full">
              <template #footer>
                <div class="flex flex-row space-x-2">
                  <div
                    class="h-full border border-2 !rounded-lg"
                    :style="{ 'border-color': item.activity.category.color }"
                  />
                  <div class="font-semibold text-gray-300 text-lg sf-pro-text">
                    {{ dayjs(item.scheduleInfo.sessionDate).locale('ru').format('D MMMM') }}
                  </div>
                </div>
                <div
                  class="font-semibold text-primary-100 text-lg sf-pro-text"
                  v-if="item.minPrice"
                >
                  от {{ item.minPrice }} ₽
                </div>
                <div
                  class="font-semibold text-primary-100 text-lg sf-pro-text"
                  v-else-if="item.tariff.type === 'free'"
                >
                  Бесплатно
                </div>
                <div
                  class="font-semibold text-primary-100 text-lg sf-pro-text"
                  v-else-if="item.tariff.type === 'offline'"
                >
                  Билеты в кассе
                </div>
              </template>
            </t-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { Swiper, Pagination } from 'swiper'
import 'dayjs/locale/ru'
import 'swiper/css/bundle'

Swiper.use([Pagination])

onMounted(() => {
  const swiper = new Swiper('.watchSwiper', {
    // freeMode: true,
    spaceBetween: 16,
    // loop: true,
    slidesPerView: 1,
    breakpoints: {
      320: {
        slidesPerView: 1.1
      },
      640: {
        slidesPerView: 2
      },
      1024: {
        slidesPerView: 3
      },
      1280: {
        slidesPerView: 4
      }
    },
    pagination: {
      el: '.swiper-pagination2',
      clickable: true
    }
  })

  return { swiper }
})

const { $get } = useApi()

const deviceId = useCookie(COOKIE_KEYS.deviceId)

const { data: viewedEvents } = await useAsyncData(
  `get viewed events by deviceId - ${deviceId.value}`,
  async () => {
    const _events = await $get(`/api/v1/viewed-events/get-by-deviceId/${deviceId.value}?_limit=3`)

    if (_events && _events.length) {
      return _events.map((item) => {
        return item.event
      })
    } else {
      return []
    }
  },
  {
    default: () => []
  }
)
</script>

<style scoped>
.swiper-slide {
  height: auto;
}
</style>
